import React from 'react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import Loading from '@cof/graffiti-alley-spray-cans/atoms/Loading';
import CardArt from '@cof/graffiti-alley-spray-cans/molecules/CardArt';
import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';
import CardReviews from '@cof/graffiti-alley-spray-cans/molecules/CardReviews';
import SecurityFunds from '@cof/graffiti-alley-spray-cans/organisms/CardFeatures/modals/SecurityFunds';
import {
  cardTypes,
  REWARDS_BRANDS,
  BUILD_BRANDS,
  DEFAULT_WEB_CHANNEL,
} from '@cof/graffiti-alley-spray-cans/utils/constants/constants';

import { featuresMap } from './feature-mapping';
import FeatureList from '../FeatureList';
import SingleCtaButton from '../SingleCtaButton';
import { useCardDetails } from '../../hooks/useCardDetails';

import './CardItem.scss';

const CardItem = injectIntl(
  ({
    intl,
    brand,
    viewDetailsUrl,
    compareFilter,
    channel,
    errorHandler,
    featuresMap,
    showNewBubble,
    showNoReviews,
  }) => {
    const { isLoading, isError, snippetDetails } = useCardDetails({
      intl,
      brand,
      channel,
      snippetNames: ['product-highlight', 'annual-fee', 'card-highlight'],
      errorHandler,
      isErrorBoundaried: false,
    });

    const productHighlight = !isLoading && snippetDetails.find(({ name }) => name === 'product-highlight')?.value;
    const annualFeeSnippet = !isLoading && snippetDetails.find(({ name }) => name === 'annual-fee');
    const cardHighlightSnippet = !isLoading && snippetDetails.find(({ name }) => name === 'card-highlight');

    if (isLoading) {
      return <Loading />;
    }

    /** When the useCardDetails returns an error we could either stop the entire component from rendering or ignore the parts
     * that require the useCardDetails and just ignore the static parts. There is some ambiguity from a UX and from a legal
     * point of view on what can and cannot be displayed. For e.g. from a legal stand point, if we are unable to show interest rates
     * then we should not be displaying annual fees. Till we can sort out a specific need it is best to render a blank and pass back the
     * error to caller using an errorHandler or an error boundary. The caller/consumer can use the error to display an alternate component
     * or a technical difficulty page.
     * */
    if (isError) {
      return null;
    }

    return (
      <div className="card-item-container">
        <div id={`${brand}-card-item`} className="card-item-compare">
          <div id={`${brand}-card-image-section`} className="compare-card-image">
            <div id={`${brand}-card-highlight`} className="compare-card-highlight">
              <h2>
                {brand === 'nfsrm' ? (
                  <FormattedMessage
                    id={`cards.details.${brand}.name`}
                    values={{
                      boldText: (
                        <b>
                          {intl.formatMessage({
                            id: `cards.details.${brand}.name`.replace(/name$/, 'values.boldText'),
                          })}
                        </b>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage id={`cards.details.${brand}.name`} />
                )}
              </h2>
            </div>

            <div id={`${brand}-text-center`} className="card-item-wrapper">
              <Link to={viewDetailsUrl}>
                <CardArt
                  brand={brand}
                  classImageStyle=""
                  showDefaultNumber={false}
                  firstName=""
                  lastName=""
                  staticCardArt
                  showNewBubble={showNewBubble}
                />
              </Link>
            </div>

            <div className="compare-ratings-and-reviews">
              <CardReviews brand={brand} legal="2" showNoReviews={showNoReviews} />
            </div>

            {REWARDS_BRANDS.includes(brand) && productHighlight && (
              <div
                id={`${brand}-product-highlight`}
                className="compare-product-highlight"
                data-testid="compare-product-highlight-test"
              >
                {brand === 'nfsrm' ? (
                  // TODO: Remove this when it's ready in API
                  <span>{intl.formatMessage({ id: 'pages.compare.highlight-text.nfsrm' })}</span>
                ) : (
                  <>
                    <span dangerouslySetInnerHTML={{ __html: productHighlight.replace(/\./g, '') }} />
                    <sup>5</sup>.
                  </>
                )}
              </div>
            )}
            {BUILD_BRANDS.includes(brand) && (
              <div className="compare-product-highlight" data-testid="compare-product-highlight-test">
                {cardHighlightSnippet?.value != null ? (
                  <span className={`card-highlight-text-content-${brand}`}>{cardHighlightSnippet.value}</span>
                ) : (
                  // TODO: Remove this fallback when this is 100% ready in API
                  <span className={`card-highlight-text-content-${brand}-alt`}>
                    {intl.formatMessage({ id: `pages.compare.highlight-text.${brand}` })}
                  </span>
                )}
              </div>
            )}
          </div>

          {(compareFilter === 'rewards' || compareFilter === 'build') && (
            <section
              id={`${brand}-compare-features`}
              className={`card-compare-redesign-features ${compareFilter === 'build' ? 'compare-build-features' : ''}`}
            >
              <header>
                <div className="card-features-title">
                  <h3>{intl.formatMessage({ id: 'pages.compare.card-features.section-title' })}</h3>
                </div>
              </header>

              <div className="card-features-content">
                <FeatureList id={brand} featuresMap={featuresMap[brand]} />
              </div>
            </section>
          )}

          <div id={`${brand}-card-feature`} className="compare-card-features">
            {annualFeeSnippet?.name && annualFeeSnippet?.text && (
              <section
                key={`${brand}-compare-${annualFeeSnippet.name}`}
                id={`${brand}-compare-${annualFeeSnippet.name}`}
                className="compare-card-fee"
              >
                {annualFeeSnippet?.value ? (
                  <span className="compare-product-highlight-content">{`${intl.formatMessage({
                    id: annualFeeSnippet.text,
                  })} ${annualFeeSnippet.value}`}</span>
                ) : (
                  <span className="compare-product-highlight-content">
                    {intl.formatMessage({ id: 'common.unavailable' })}
                  </span>
                )}
              </section>
            )}
            <section className="compare-range-pricing-wrapper" data-testid="compare-range-pricing-wrapper-test">
              <span className="compare-product-highlight-content">
                {intl.formatMessage({ id: 'pages.compare.range-pricing.title' })}
              </span>
              <span className="compare-product-highlight-content">
                {intl.formatMessage({ id: 'pages.compare.range-pricing.value' })}
              </span>
            </section>
            {compareFilter === 'all' && BUILD_BRANDS.includes(brand) && (
              <section className="card-security-funds">
                <FormattedMessage
                  id="pages.compare.security-funds-prompt.required"
                  values={{
                    req: (
                      <strong>
                        <FormattedMessage
                          id={`pages.compare.security-funds-prompt.meta.${
                            brand === 'gsm' ? 'required' : 'may-be-required'
                          }`}
                        />
                      </strong>
                    ),
                    modal: (
                      <ModalLink
                        target={SecurityFunds}
                        title={intl.formatMessage({ id: 'pages.compare.card-features.security-funds-question' })}
                        linkIcon={false}
                      >
                        <FormattedMessage id="pages.compare.card-features.security-funds-question" />
                      </ModalLink>
                    ),
                  }}
                />
              </section>
            )}
          </div>

          <Link
            className={`card-detail-link ${
              compareFilter === 'all' && BUILD_BRANDS.includes(brand) ? 'card-detail-link-build' : ''
            }`}
            to={viewDetailsUrl}
            chevron
          >
            {intl.formatMessage({ id: `cards.details.${brand}.viewDetails` })}
          </Link>
          <div id={`${brand}-apply-now-button-below`} className="apply-now-button text-center">
            <SingleCtaButton
              brand={brand}
              ctaClassName="preapprove-cta-button"
              url="/quickcheck/"
              content={<FormattedMessage id="pages.product-details.single-cta.cta-short" />}
            />
          </div>
        </div>
      </div>
    );
  },
);

CardItem.propTypes = {
  /** Internationalization object, no need to pass */
  intl: intlShape,
  /** Brand of the card */
  brand: PropTypes.oneOf(cardTypes).isRequired,
  /** Determine if the view details link will appear and where it will navigate to */
  viewDetailsUrl: PropTypes.string.isRequired,
  /** Determine which sub sections and styling the cardItem should display for www compare page filter */
  compareFilter: PropTypes.oneOf(['all', 'rewards', 'build']).isRequired,
  /** Optional path to use an alternative image for Card Art */
  // cardArtImagePath: PropTypes.string,
  /** Channel for which to serve up the product details. Value for this prop will default to CA_UNS_WEB.
   * These are usually values setup in iCatalyst.
   * For e.g CA_UNS_WEB is the typical Canada unsolicited web product
   *         CA_UNS_WEB_FR is the Canada french unsolicited web product
   */
  channel: PropTypes.string,
  /** Error handler callback to allow consumers to respond to errors that may occur from calls to the
   * Canada products API. The error object would be passed back to the callback. On parsing the
   * error.message, a consumer could pull out the statusCode and statusText of the error if required.
   * The custom error handler offers flexibility to respond to an error based on individual needs.
   * If a custom error handler is passed, it will always take priority in how errors are handled by
   * this component.
   */
  errorHandler: PropTypes.func,
  /** Set this value to `true` if you wrap the `CardItem` component in any ErrorBoundary component. If this
   * is set to true and there is no `errorHandler` passed into this component, an error will be thrown up so
   * the ErrorBoundary component that you use to wrap CardItem can catch the error and render an alternate.
   */
  // isErrorBoundaried: PropTypes.bool,
  //Set this to pass down flag to CardArt to show "new" bubble on top of card art
  showNewBubble: PropTypes.bool,
  //Set this to pass down flag to CardReview to show "There are no reviews yet"
  showNoReviews: PropTypes.bool,
  /** predefined features that mapped to brand */
  featuresMap: PropTypes.object,
};

CardItem.defaultProps = {
  brand: 'gm',
  viewDetailsUrl: '',
  cardArtImagePath: '',
  channel: DEFAULT_WEB_CHANNEL,
  showLegalDisclaimer: false,
  showNewBubble: false,
  showNoReviews: false,
  featuresMap: featuresMap,
};

export default CardItem;
