import React from 'react';

export const useWindowWidthSize = () => {
  const [width, setWidth] = React.useState(typeof window !== 'undefined' ? window?.innerWidth : 0);
  React.useEffect(() => {
    const updateWidth = () => {
      if (!window?.innerWidth) return;
      setWidth(window.innerWidth);
    };
    window.addEventListener('load', updateWidth);
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('load', updateWidth);
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return width;
};
