import React from 'react';
import PropTypes from 'prop-types';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import { FormattedMessage, intlShape } from 'react-intl';
import { Link as LighthouseLink } from '@cof/lighthouse-component-library';
import { useWindowWidthSize } from '../../hooks/useWindowWidthSize';
import './SingleCtaButton.scss';
import { ALL_BRANDS } from '../../lib/constants';
import { addCheckFirstQS } from '../../lib/urls';

const MOBILE_BREAK_POINT = 640;

/** This button should only be used for enabling check first flow as
 *  it's going to append expected querystring based on the 'brand' state
 * */
const SingleCtaButton = ({
  ariaDescribedBy,
  ctaClassName,
  url,
  brand,
  ariaLabel,
  content,
  useRedesignLink = false,
}) => {
  const windowWidth = useWindowWidthSize();
  if (useRedesignLink) {
    return (
      <LighthouseLink
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedBy}
        className={`button action apply-now-button preapprove-button legacy-link-style ${brand}-preapprove-cta${
          ctaClassName == null ? '' : ` ${ctaClassName}`
        }`}
        to={addCheckFirstQS(url, brand)}
        data-test={`${brand}-single-cta-button`}
        data-testid={`${brand}-single-cta-button`}
        isButton
      >
        {content ? (
          content
        ) : windowWidth < MOBILE_BREAK_POINT ? (
          <FormattedMessage id="pages.product-details.single-cta.cta-short" />
        ) : (
          <FormattedMessage id="pages.product-details.single-cta.cta-long" />
        )}
      </LighthouseLink>
    );
  }
  return (
    <Link
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
      className={`button large action apply-now-button preapprove-button legacy-cta-link ${brand}-preapprove-cta${
        ctaClassName == null ? '' : ` ${ctaClassName}`
      }`}
      to={addCheckFirstQS(url, brand)}
      data-test={`${brand}-single-cta-button`}
      data-testid={`${brand}-single-cta-button`}
    >
      {content ? (
        content
      ) : windowWidth < MOBILE_BREAK_POINT ? (
        <FormattedMessage id="pages.product-details.single-cta.cta-short" />
      ) : (
        <FormattedMessage id="pages.product-details.single-cta.cta-long" />
      )}
    </Link>
  );
};

SingleCtaButton.propTypes = {
  intl: intlShape,
  brand: PropTypes.oneOf(ALL_BRANDS).isRequired,
  url: PropTypes.string.isRequired,
  ctaClassName: PropTypes.string,
  /** This prop should not be passed if ariaLabel provided */
  ariaDescribedBy: PropTypes.string,
  /** This prop should not be passed if ariaDescribedBy provided */
  ariaLabel: PropTypes.string,
  /** Customized formatted massages */
  content: PropTypes.element,
  /** This is flag to use the new Lighthouse provider */
  useRedesignLink: PropTypes.bool,
};

export default SingleCtaButton;
