import { useQuery } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';

import {
  REWARDS_BRANDS,
  CARD_IDS,
  LANGUAGES,
  DEFAULT_CACHING_LIMIT_IN_MILLISECONDS,
  ALL_SNIPPETS,
  CARDS_PRODUCT_ENDPOINT,
} from '../lib/constants';
import { getBaseApiUrl } from '../lib/urls';

const DEFAULT_WEB_CHANNEL = 'CA_UNS_WEB';

const deriveChannelCode = (channel, locale) => {
  const { FRENCH } = LANGUAGES;
  //if locale is french and the channel code passed in does not end with `_FR` then add `_FR`
  return locale === FRENCH && channel.split('_').pop() !== FRENCH ? `${channel}_${FRENCH.toUpperCase()}` : channel;
};

const getCardDetails = async (brandString, channel, locale) => {
  const channelCode = deriveChannelCode(channel, locale);
  const options = { method: 'GET', headers: { Accept: 'application/json;v=1' } };
  const cardDetailsUrl = `${getBaseApiUrl()}${CARDS_PRODUCT_ENDPOINT}?externalBrandCode=${brandString}&channelCode=${channelCode}`;
  return await fetch(cardDetailsUrl, options).then(async (response) => {
    /** fetch API only throws an error on network failure, to allow users of this hook to
     * know what errors occur, we check for the `ok` status and when there is an error with
     * the fetch call, we pass on the status and the text back so users can perform custom
     * error handling logic
     */
    if (!response.ok) {
      throw new Error(
        JSON.stringify({ status: response.status, statusText: response.statusText, brandString, channel, locale }),
      );
    }
    return await response.json();
  });
};

const parseTextForName = (name, brand, options) => {
  let parsedText;

  switch (name) {
    case 'purchase':
      if (options.type === 'detail') {
        parsedText = 'cards.details.purchases-transfers-title';
        const brandsWithCombinedRates = ['gm', 'gsm', 'acp', 'atp'];
        if (brandsWithCombinedRates.includes(brand)) {
          parsedText = 'cards.details.standard-rates-title';
        }
      } else {
        parsedText = 'cards.details.purchase-title';
      }
      break;
    case 'rewards':
      parsedText = `cards.details.${brand}.rewards.description`;
      break;
    case 'balance-transfer':
      parsedText = `cards.details.balance-transfer-title`;
      break;
    case 'cash-advance':
      parsedText = `cards.details.cash-advance-title`;
      break;
    default:
      if (brand === 'mmf' && name !== 'credit-limit') {
        parsedText = 'cards.details.monthly-fee-title';
      } else {
        parsedText = `cards.details.${name}-title`;
      }
      break;
  }

  return parsedText;
};

const parseValueForName = (name, brand, intl, snippetName, { productDisplayContent = null }) => {
  const langKeys = intl.locale.includes('fr') ? LANGUAGES.FRENCH_ALL : LANGUAGES.ENGLISH_ALL;
  let parsedValue = null;
  if (productDisplayContent) {
    parsedValue = productDisplayContent
      .filter(({ snippetType, languageCode }) => snippetType === snippetName && langKeys.includes(languageCode))
      .reduce((_, { snippetText }) => snippetText, null);
  }

  // Note: currently rewards are not pulled from iCatalyst, instead the value is displayed from local messages file
  if (REWARDS_BRANDS.includes(brand) && name === 'rewards') {
    return intl.formatMessage({ id: `cards.details.${brand}.rewards.value` });
  }

  return parsedValue;
};

const parseCardDetails = (brand, cardDetails, intl, snippets = {}, options = {}) => {
  return Object.entries(snippets).map(([name, value]) => ({
    name,
    text: parseTextForName(name, brand, options),
    value: parseValueForName(name, brand, intl, value, cardDetails),
  }));
};

/** This hook is opinionated on the caching strategy. We decide to cache based on brand, channel and locale. So any
 * consumers of this hook will benefit from reduced network calls to the API. If there are cases when the consumers
 * have some custom caching need, they can pass in a string value to `customQueryKey` property within the `options`
 * parameter that this hook receives. An e.g. of this could be that <CardItem/> component that renders other components
 * like <CardFee/> and <ApplyNow/> which also uses this hook, could have its own caching by passing an options object
 * that has the `customQueryKey: "cardItem"`. This will ensure that `CardItem` gets its own cache (brand + channel +
 * locale + customQueryKey) and the individual components can all use a common default cache using the default
 * (brand + channel + locale) query key.
 */
export const useCardDetails = ({
  intl,
  brand: brandParam,
  channel = DEFAULT_WEB_CHANNEL,
  snippetNames = [],
  options = {},
  errorHandler,
  isErrorBoundaried = false,
  cacheStaleTime = DEFAULT_CACHING_LIMIT_IN_MILLISECONDS,
}) => {
  let brand = brandParam;
  if (brandParam === 'gm') {
    brand = 'gm22pct';
  } else if (brandParam === 'gsm') {
    brand = 'gsm22pct';
  }
  let cardDetails = {};
  let snippetDetails = [];
  let productId = null;

  const brandString = CARD_IDS[brand] ? CARD_IDS[brand].externalBrandCode : null;

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['cardDetails', { brandString, channel, locale: intl.locale, customQueryKey: options.customQueryKey }],
    queryFn: () => getCardDetails(brandString, channel, intl.locale),
    refetchOnWindowFocus: false,
    staleTime: cacheStaleTime,
    cacheTime: DEFAULT_CACHING_LIMIT_IN_MILLISECONDS,
  });
  const handleErrorUsingErrorBoundary = useErrorHandler();

  /** With the possibility of an API call through the `useQuery` throwing an error, there are some parameters passed into this hook
   * that will aid in error handling. When the useQuery returns an error, the error handling logic is based on priority. If there
   * is a custom errorHandler passed in as argument to this hook, the priority to handle error goes there first. This is because we
   * want the consumers to handle the error in a way that they would like. If there is no error handler, use the isErrorBoundaried argument
   * to know if the consumer has any ErrorBoundary component (this could be a **custom error boundary** or the **ErrorBoundary** component
   * from `react-error-boundary`). If the consumer indicate that they have an error boundary setup, then we use the `useErrorHandler` from
   * react-error-boundary to throw an error. This will ensure that the encompassing ErrorBoundary can catch the error and display what
   * it needs to as an alternate. Finally, if there is no custom error handler or no error boundary, to keep the application rendering
   * from breaking, this hook will just return the error as is.
   */
  if (isError) {
    if (errorHandler) {
      errorHandler(error);
    } else if (isErrorBoundaried) {
      handleErrorUsingErrorBoundary(error);
    }
  }

  if (data && data.products && data.products.length) {
    cardDetails = data.products[0];

    if (cardDetails.externalBrandCode === CARD_IDS[brand].externalBrandCode) {
      productId = cardDetails.productId;

      if (!snippetNames.length) {
        snippetDetails = parseCardDetails(brand, cardDetails, intl, ALL_SNIPPETS, options);
      } else {
        const snippetsToParse = snippetNames.reduce((snippets, snippetName) => {
          if (snippetName in ALL_SNIPPETS) {
            snippets = { ...snippets, [`${snippetName}`]: ALL_SNIPPETS[snippetName] };
          }
          return snippets;
        }, {});

        snippetDetails = parseCardDetails(brand, cardDetails, intl, snippetsToParse, options);
      }
    }
  }

  return { isLoading, isError, error, cardDetails, snippetDetails, productId };
};
