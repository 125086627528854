import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { SprayCanContext } from '@cof/graffiti-alley-spray-cans/context/app-context';
import PrivacyPolicyNotification from '../PrivacyBanner';
import Header from '../Header';
import Footer from '../Footer';
import { getCorrelationId } from '../../lib/correlationId';
import IncidentBanner from '../IncidentBanner';

import './Layout.scss';

const Layout = ({ children, pageName, className, renderFooter, renderHeader, shouldDisplayIncidentBanner }) => {
  const { setCorrelationId } = useContext(SprayCanContext);
  React.useEffect(() => setCorrelationId(getCorrelationId()), []); // eslint-disable-line react-hooks/exhaustive-deps
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className={`page-layout ${className}`} data-for={pageName}>
      <PrivacyPolicyNotification />
      {shouldDisplayIncidentBanner && <IncidentBanner path={pageName} />}
      {typeof renderHeader === 'function' ? renderHeader() : <Header siteTitle={data.site.siteMetadata.title} />}
      <main id="main">{children}</main>
      {typeof renderFooter === 'function' ? (
        renderFooter()
      ) : typeof window !== 'undefined' ? (
        <Footer showUpdatedFRLegalText={['/fr/', '/credit-cards/compare/fr/'].includes(window?.location?.pathname)} />
      ) : (
        <Footer />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  className: PropTypes.string,
  renderFooter: PropTypes.func,
  renderHeader: PropTypes.func,
  shouldDisplayIncidentBanner: PropTypes.bool,
};

Layout.defaultProps = {
  pageName: '',
  className: '',
  shouldDisplayIncidentBanner: false,
};

export default Layout;
