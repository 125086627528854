import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';

import ModalLink from '@cof/graffiti-alley-spray-cans/molecules/ModalLink';

const FeatureList = ({ intl, id, featuresMap, ...props }) => {
  if (featuresMap.length === 0) {
    return;
  }

  const featureList = featuresMap.map(({ textId, sup, modal }, i) => {
    const values = modal
      ? {
          'security-question': (
            <ModalLink
              target={() => <FormattedMessage id="pages.compare.card-features.security-funds-explanation" tag="p" />}
              title={'pages.compare.card-features.security-funds-question'}
              linkIcon={false}
            >
              {intl.formatMessage({ id: 'pages.compare.card-features.security-funds-question' })}
            </ModalLink>
          ),
        }
      : undefined;
    return (
      <li key={i} className={`${id}-features-${i}`}>
        <FormattedMessage id={textId} values={values} />
        {sup && <sup>{sup}</sup>}
      </li>
    );
  });

  return (
    <ul className="features-list" {...props}>
      {featureList}
    </ul>
  );
};

FeatureList.propTypes = {
  /** Internationalization object - No need to pass. Injected via the injectIntl HOC */
  intl: intlShape,
  /** feature list ID */
  id: PropTypes.string.isRequired,
  /** mapping where you can indicate which feature to show for which type, any action associated with each feature (or part of it), as well as footnote signs */
  featuresMap: PropTypes.array.isRequired,
};

// exporting the raw class (class without HOCs)
export { FeatureList as FeatureListRaw };

const FeatureListHOC = injectIntl(FeatureList);
FeatureListHOC.displayName = 'FeatureList';

export default FeatureListHOC;
