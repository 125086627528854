import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import WebFooter from '@cof/graffiti-alley-spray-cans/organisms/WebFooter';
import WebFooterNavigation from '../WebFooterNavigation';

const Footer = injectIntl(({ intl, showUpdatedFRLegalText }) => {
  return (
    <WebFooter id="website-footer" showYouTubeAndInstagramIcon={intl.locale === 'en'}>
      <WebFooterNavigation showUpdatedFRLegalText={showUpdatedFRLegalText} />
    </WebFooter>
  );
});

Footer.propTypes = {
  intl: intlShape,
  showUpdatedFRLegalText: PropTypes.bool,
};

Footer.displayName = 'Footer';

export default Footer;
