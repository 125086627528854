export const featuresMap = {
  gm: [
    {
      textId: 'cards.details.gm.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gm.features.1',
    },
    {
      textId: 'cards.details.gm.features.2',
    },
    {
      textId: 'cards.details.gm.features.3',
      modal: true,
    },
  ],
  gm22pct: [
    {
      textId: 'cards.details.gm22pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gm22pct.features.1',
    },
    {
      textId: 'cards.details.gm22pct.features.2',
    },
    {
      textId: 'cards.details.gm22pct.features.3',
      modal: true,
    },
  ],
  gm26pct: [
    {
      textId: 'cards.details.gm26pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gm26pct.features.1',
    },
    {
      textId: 'cards.details.gm26pct.features.2',
    },
    {
      textId: 'cards.details.gm26pct.features.3',
      modal: true,
    },
  ],
  gm30pct: [
    {
      textId: 'cards.details.gm30pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gm30pct.features.1',
    },
    {
      textId: 'cards.details.gm30pct.features.2',
    },
    {
      textId: 'cards.details.gm30pct.features.3',
      modal: true,
    },
  ],
  gsm: [
    {
      textId: 'cards.details.gsm.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gsm.features.1',
    },
    {
      textId: 'cards.details.gsm.features.2',
    },
    {
      textId: 'cards.details.gsm.features.4',
      modal: true,
    },
  ],
  gsm22pct: [
    {
      textId: 'cards.details.gsm22pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gsm22pct.features.1',
    },
    {
      textId: 'cards.details.gsm22pct.features.2',
    },
    {
      textId: 'cards.details.gsm22pct.features.3',
      modal: true,
    },
  ],
  gsm26pct: [
    {
      textId: 'cards.details.gsm26pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gsm26pct.features.1',
    },
    {
      textId: 'cards.details.gsm26pct.features.2',
    },
    {
      textId: 'cards.details.gsm26pct.features.3',
      modal: true,
    },
  ],
  gsm30pct: [
    {
      textId: 'cards.details.gsm30pct.features.0',
      sup: '3',
    },
    {
      textId: 'cards.details.gsm30pct.features.1',
    },
    {
      textId: 'cards.details.gsm30pct.features.2',
    },
    {
      textId: 'cards.details.gsm30pct.features.3',
      modal: true,
    },
  ],
  atp: [
    {
      textId: 'cards.details.atp.features.0',
    },
    {
      textId: 'cards.details.atp.features.1',
    },
    {
      textId: 'cards.details.atp.features.2',
    },
  ],
  acp: [
    {
      textId: 'cards.details.acp.features.0',
    },
    {
      textId: 'cards.details.acp.features.1',
    },
    {
      textId: 'cards.details.acp.features.2',
    },
  ],
  nfsrm: [
    {
      textId: 'cards.details.nfsrm.features.0',
      sup: '4',
    },
    {
      textId: 'cards.details.nfsrm.features.1',
    },
    {
      textId: 'cards.details.nfsrm.features.2',
    },
  ],
};
