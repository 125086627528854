import React, { useState } from 'react';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Menu, MenuItem } from '@cof/graffiti-alley-spray-cans/molecules/Menu';

import './WebFooterNavigation.scss';

const WebFooterNavigation = injectIntl(({ intl, showUpdatedFRLegalText = false }) => {
  const [showAbout, toggleAbout] = useState(false);
  const [showLegal, toggleLegal] = useState(false);
  const [showLearn, toggleLearn] = useState(false);
  const [showOnline, toggleOnline] = useState(false);
  const isFrench = intl.locale === 'fr';

  return (
    <section id="web-footer-navigation">
      <div id="footer-navigation-content" className="container-body">
        <section id="footer-about-block">
          <header
            className={`${showAbout ? 'opened' : ''}`}
            onClick={() => toggleAbout(!showAbout)}
            role="button"
            tabIndex="0"
            onKeyUp={() => toggleAbout(!showAbout)}
          >
            <div id="footer-about-title" className="plain-text">
              <FormattedMessage id="navfooter.about.about-us" />
            </div>
          </header>
          <div id="footer-about-block-content" className={`container-body${showAbout ? ' show' : ''}`}>
            <Menu id="footer-about-list" orientation="vertical">
              <MenuItem url="/about/corporate-information/" className="about-capital-one-link">
                <FormattedMessage id="navfooter.about.about-capitalone" />
              </MenuItem>
              <MenuItem
                url={intl.formatMessage({ id: 'navheader.link.life-and-credit.url' })}
                className="service-link"
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="navheader.link.life-and-credit.text" />
              </MenuItem>
              {!isFrench && (
                <MenuItem
                  url={intl.formatMessage({ id: 'navfooter.about.careers-link' })}
                  className="careers-link"
                  title={intl.formatMessage({ id: 'navfooter.about.careers-title' })}
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="navfooter.about.careers" />
                </MenuItem>
              )}
            </Menu>
          </div>
        </section>

        <section id="footer-legal-block">
          <header
            className={`${showLegal ? 'opened' : ''}`}
            onClick={() => toggleLegal(!showLegal)}
            role="button"
            tabIndex="0"
            onKeyUp={() => toggleLegal(!showLegal)}
          >
            <div id="footer-legal-title" className="plain-text">
              <FormattedMessage
                id={showUpdatedFRLegalText ? 'navfooter.legal.legal-updated' : 'navfooter.legal.legal'}
              />
            </div>
          </header>

          <div id="footer-legal-block-content" className={`container-body${showLegal ? ' show' : ''}`}>
            <Menu id="footer-legal-list" orientation="vertical">
              <MenuItem
                url="/about/terms-of-use/"
                className="terms-link"
                title={intl.formatMessage({ id: 'navfooter.legal.terms-title' })}
              >
                <FormattedMessage id="navfooter.legal.terms" />
              </MenuItem>
              <MenuItem
                url="/privacypolicy/"
                className="privacy-link"
                title={intl.formatMessage({ id: 'navfooter.legal.privacy-title' })}
              >
                <FormattedMessage id="navfooter.legal.privacy" />
              </MenuItem>
              <MenuItem url="/privacypolicy/#oba" className="ad-choices-link">
                <FormattedMessage id="navfooter.legal.adChoices" />
              </MenuItem>
            </Menu>
          </div>
        </section>

        <section id="footer-learn-more-block">
          <header
            className={`${showLearn ? 'opened' : ''}`}
            onClick={() => toggleLearn(!showLearn)}
            role="button"
            tabIndex="0"
            onKeyUp={() => toggleLearn(!showLearn)}
          >
            <div id="footer-learn-more-title" className="plain-text">
              <FormattedMessage id="navfooter.learn.learn-more" />
            </div>
          </header>
          <div id="footer-learn-more-block-content" className={`container-body${showLearn ? ' show' : ''}`} role="none">
            <Menu id="footer-learn-more-list" orientation="vertical">
              <MenuItem url="/contact-us/capitalonecards/" className="contact-us-link">
                <FormattedMessage id="navfooter.learn.contact-us" />
              </MenuItem>
              <MenuItem
                url={intl.formatMessage({ id: 'navheader.link.support-centre.url' })}
                className="support-centre"
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="navheader.link.support-centre.text" />
              </MenuItem>
              <MenuItem
                url={intl.formatMessage({ id: 'navfooter.learn.accessibility-link' })}
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="navfooter.learn.accessibility" />
              </MenuItem>
              <MenuItem
                url={intl.formatMessage({ id: 'navfooter.learn.cyber-incident-link' })}
                target="_blank"
                rel="noopener"
              >
                <FormattedMessage id="navfooter.learn.cyber-incident" />
              </MenuItem>
            </Menu>
          </div>
        </section>

        <section id="footer-online-banking-block">
          <header
            className={`${showOnline ? 'opened' : ''}`}
            onClick={() => toggleOnline(!showOnline)}
            role="button"
            tabIndex="0"
            onKeyUp={() => toggleOnline(!showOnline)}
          >
            <div id="footer-online-banking-title" className="plain-text">
              <FormattedMessage id="navfooter.online.online-banking" />
            </div>
          </header>
          <div id="footer-online-banking-block-content" className={`container-body${showOnline ? ' show' : ''}`}>
            <Menu id="footer-online-banking-list" orientation="vertical">
              <MenuItem url="/sign-in/" className="sign-in-link">
                <FormattedMessage id="navfooter.online.sign-in" />
              </MenuItem>
            </Menu>
          </div>
        </section>
      </div>
    </section>
  );
});

WebFooterNavigation.propTypes = {
  intl: intlShape,
  showUpdatedFRLegalText: PropTypes.bool,
};

WebFooterNavigation.displayName = 'WebFooterNavigation';

export default WebFooterNavigation;
