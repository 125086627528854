import React from 'react';
import { FormattedMessage } from 'react-intl';
import { superScript } from '../../lib/utils';

const LegalModalContent = () => (
  <div id="product-list-page-legal-content" className="plain-text">
    <h2>
      <FormattedMessage id="navfooter.legal.legal-updated" />
    </h2>
    <p>
      <strong>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal" values={{ star: superScript('one') }} />
      </strong>
    </p>
    <ol type="a">
      <li>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal-li-0" />
      </li>
      <li>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal-li-1" />
      </li>
      <li>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal-li-2" />
      </li>
      <li>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal-li-3" />
      </li>
      <li>
        <FormattedMessage id="modals.legal-modal.quickcheck-legal-li-4" />
      </li>
    </ol>
    <p>
      <FormattedMessage
        id="modals.home-page-legal-modal.disclaimer"
        values={{ rball: superScript('rball'), md: <sup>MD</sup> }}
      />
    </p>
    <p>
      <sup>2</sup>
      &nbsp;
      <FormattedMessage id="modals.legal-modal.sup2" />
    </p>

    <p className="margin-bottom-0">
      <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3" values={{ sup3: <sup>3</sup> }} />
    </p>

    <ul>
      <li>
        <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-1" />
      </li>
      <li>
        <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-2" />
      </li>
      <li>
        <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-3" />
      </li>
      <li>
        <FormattedMessage id="pages.pdp-gm.legal-disclaimer.info.p3-4" />
      </li>
    </ul>

    <p>
      <sup>4</sup>
      &nbsp;
      <FormattedMessage id="modals.legal-modal.sup4" />
    </p>

    <p>
      <sup>5</sup>
      &nbsp;
      <FormattedMessage id="modals.legal-modal.sup5" />
    </p>
    <p>
      <FormattedMessage id="modals.legal-modal.mastercard-tm" />
    </p>
  </div>
);

export default LegalModalContent;
